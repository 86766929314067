/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

//require("prismjs/themes/prism-solarizedlight.css")
//require("prismjs/themes/prism-dark.css")
//require("prismjs/themes/prism-coy.css")
//require("prismjs/themes/prism-funky.css")
//require("prismjs/themes/prism-okaidia.css")
require("prismjs/themes/prism-tomorrow.css")
//require("prismjs/themes/prism-twilight.css")
//require("prismjs/themes/prism.css")
